<template>
  <header
    :class="navClasses"
    class="inset-x-0 z-[999] hidden w-full transition-all lg:block font-universe-lt-std"
  >
    <client-only>
      <div class="transition-all">
        <!-- Top navbar -->
        <div
          :class="[
            state.scrollPosition >= 40 && !state.isProductPage
              ? 'bg-white'
              : 'bg-transparent',
            'h-[46px] relative z-[999]'
          ]"
        >
          <div class="container flex items-center justify-between h-full">
            <!-- Locale selector -->
            <LazyLocaleSelector
              :color="color"
              :name="state.isProductPage ? 'headerproduct' : 'header'"
              align="left"
            />

            <!-- User selector -->
            <UserDropdown
              v-if="store.enableShop"
              :color="
                state.scrollPosition >= 40 && !state.isProductPage
                  ? '#0f0f0f'
                  : color
              "
              :text-color="
                state.scrollPosition >= 40 && !state.isProductPage
                  ? 'text-[#0f0f0f]'
                  : 'text-white'
              "
            />
          </div>
        </div>
        <div class="relative">
          <div
            class="container grid justify-between grid-cols-2 gap-4 h-[74px]"
          >
            <nav class="flex items-center space-s-6">
              <!-- Models -->

              <LazyFlyoutMenu
                v-if="products && products.type1 && products.type1.length > 0"
                is-product
                type="type1"
              >
                <a
                  :class="[
                    state.isProductPage ? 'nav-product' : '',
                    newTextClass
                  ]"
                  class="tracking-wider uppercase cursor-pointer nav-link hover-effect rtl:ml-6"
                >
                  {{ $t('common.e-bikes') }}
                </a>
              </LazyFlyoutMenu>
              <LazyFlyoutMenu
                v-if="products && products.type2 && products.type2.length > 0"
                is-product
                type="type2"
              >
                <a
                  :class="[
                    state.isProductPage ? 'nav-product' : '',
                    newTextClass
                  ]"
                  class="tracking-wider uppercase cursor-pointer nav-link hover-effect rtl:ml-6"
                >
                  {{ $t('common.e-motors') }}
                </a>
              </LazyFlyoutMenu>

              <!-- <FlyoutMenu
              v-if="
                categories && categories.length > 0 && store.country !== 'us'
              "
            >
              <a
                :class="[
                  state.state.isProductPage ? 'nav-product' : '',
                  newTextClass,
                ]"
                class="cursor-pointer nav-link hover-effect rtl:ml-6"
              >
                {{ $t('common.accessories') }}
              </a>
            </FlyoutMenu> -->
              <!-- about-us -->
              <nuxt-link
                :to="$i18nPath(store.pageLinks['about-us'])"
                :class="state.isProductPage ? 'nav-product' : ''"
                :prefetch="false"
                class="tracking-wider uppercase nav-link hover-effect rtl:ml-6"
              >
                {{ $t('common.about_us') }}
              </nuxt-link>
            </nav>

            <!-- Logo -->
            <nuxt-link
              :to="$i18nPath('/')"
              :prefetch="false"
              class="absolute transition-all transform -translate-s-1/2 rtl:left-[50%] top-[15px]"
              :class="store.locale !== 'ar' ? 'start-1/2' : ''"
            >
              <Logo
                :class="
                  state.scrollPosition >= 40 && !state.isProductPage
                    ? 'h-[45px]'
                    : 'h-[50px]'
                "
                class="transition-all"
              />
            </nuxt-link>
            <nav
              :class="
                state.scrollPosition >= 40 && !state.isProductPage
                  ? 'my-[20px]'
                  : 'my-[30px]'
              "
              class="flex items-center justify-end space-s-6"
            >
              <!-- Shop online -->
              <nuxt-link
                v-if="store.enableShop"
                :prefetch="false"
                :to="$i18nPath('/search')"
                :class="[
                  state.isProductPage ? 'nav-product' : '',
                  newTextClass
                ]"
                class="tracking-wider uppercase nav-link hover-effect rtl:ml-6"
              >
                {{ $t('common.shop_online') }}
              </nuxt-link>

              <!-- Stores -->
              <!-- <nuxt-link
              v-if="store.enableShop"
              :to="$i18nPath('/stores')"
               class="nav-link hover-effect"
              :class="[dynamicLinkClass, newTextClass]"
              :prefetch="false"
            >
              {{ $t('common.stores') }}
            </nuxt-link> -->

              <!-- Contact us -->
              <nuxt-link
                :to="$i18nPath(store.pageLinks['contact'])"
                class="tracking-wider uppercase nav-link hover-effect rtl:ml-6"
                :class="[dynamicLinkClass, newTextClass]"
                :prefetch="false"
              >
                {{ $t('common.contact_us') }}
              </nuxt-link>

              <!-- <nuxt-link
              :to="$i18nPath('/how-to-buy')"
              :class="[state.isProductPage ? 'nav-product' : '',newTextClass]"
              :prefetch="false"
              v-text="$t('common.how_to_buy')"
             class="tracking-wider uppercase nav-link hover-effect"
            /> -->
            </nav>
          </div>
        </div>
      </div>
    </client-only>
  </header>
</template>
<script setup>
import { useMainStore } from '@/store/index'

const nuxtApp = useNuxtApp()
const store = useMainStore()
const route = useRoute()
const router = useRouter()

const $i18nPath = nuxtApp.$i18nPath
const color = ref('white')
const state = reactive({
  isStorePage: false,
  isProductPage: false,
  scrollPosition: 0
})

const navClasses = computed(() => {
  const classes = []
  /* } else if (removeLastDash($i18nPath($route.path)) === removeLastDash($i18nPath('/'))) {
    classes.push('bg-opacity-20')
  } */
  if (route.path.includes('/products/')) {
    classes.push('md:absolute md:mb-[-110px] bg-opacity-0')
  } else {
    classes.push('md:fixed')
  }

  if (state.scrollPosition >= 40 && !state.isProductPage) {
    classes.push('md:fixed bg-black bg-opacity-70 backdrop-blur-lg pt-0')
  } else {
    classes.push('pt-4')
  }
  return classes
})

const newTextClass = computed(() => {
  if (store.locale === 'el' || store.locale === 'ar') {
    return 'xl:text-[20px] text-[16px]'
  } else {
    return 'xl:text-[28px] text-[24px]'
  }
})
const dynamicLinkClass = computed(() => {
  return state.isProductPage ? 'nav-product' : ''
})

const products = store.products
const categories = store.categories
const currentSite = store.currentSite
const currentSiteSettings = store.currentSiteSettings

watch(
  router.currentRoute,
  (newValue, oldValue) => {
    if (newValue.name == 'country-products-slug') {
      state.isProductPage = true
    } else {
      state.isProductPage = false
    }
  },
  { deep: true }
)

onBeforeMount(() => {
  if (!import.meta.browser) {
    return
  }
  state.isProductPage = window.location.href.includes('/products/')
})
onMounted(() => {
  if (!import.meta.browser) {
    return
  }

  window.addEventListener('scroll', updateScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', updateScroll)
})

function updateScroll() {
  state.scrollPosition = window.scrollY
}

function changeColor(color) {
  color.value = color
}
</script>

<style lang="postcss">
.mm-spn:dir(rtl) li:before {
  margin-right: 84%;
}
.directionltr {
  direction: ltr;
}
.directionrtl {
  direction: rtl;
}
.nav-link {
  @apply block z-10 text-2xl font-light hover:no-underline focus:no-underline text-white hover:text-white focus:outline-none relative;

  &.nav-product {
    @apply text-white;
    &.hover-effect {
      &::before {
        @apply bg-white;
      }
    }
  }

  &.nuxt-link-exact-active {
    @apply cursor-default;

    &.hover-effect::before {
      transform: scaleX(1);
    }
  }

  &.hover-effect::before,
  &.hover-effect::after {
    content: '';
    position: absolute;
    transition: transform 0.3s ease;
  }

  &.hover-effect {
    &::before {
      @apply bg-white;

      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
    }
  }

  &.hover-effect:hover::before {
    transform: scaleX(1);
  }
}
</style>
