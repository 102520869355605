// plugins/priceFormatter.js
import { useMainStore } from '../store/index'

export default defineNuxtPlugin((app) => {
  const store = useMainStore()
  const nuxtApp = useNuxtApp()

  // Retrieve currency and placement from the store or use defaults
  const currency =
    store &&
    store &&
    store.currentSite &&
    store.currentSite.default_currency_token
      ? store.currentSite.default_currency_token
      : '$'

  const placement =
    store &&
    store &&
    store.currentSite &&
    store.currentSite.default_currency_place
      ? store.currentSite.default_currency_place
      : 'left'

  // Function to format the price with currency placement
  const formatPrice = (price) => {
    if (placement === 'right') {
      return `${price} ${currency}`
    } else {
      return `${currency} ${price}`
    }
  }

  // Inject the formatPrice function into Vue instances, components, and context
  nuxtApp.provide('formatPrice', formatPrice)
  nuxtApp.provide('currency', currency)

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value)
  }

  nuxtApp.provide('getKeyByValue', getKeyByValue)
})
