import i18next from "i18next";
import { useAuthStore } from "./auth";
import { useLocaleStore } from "./locales";
import { defineStore } from "pinia";

// App unsaved state
export const useMainStore = defineStore("main", {
  state: () => ({
    country: null,
    locale: "en",
    categories: [],
    products: [],
    features: [],
    currentSite: null,
    currentSiteSettings: null,
    maintenance: false,
    defaultRoute: null,
    sites: [],
    isRtl: false,
    enableShop: false,
    translationData: [],
    isLoading: true,
    pageLinks: null,
    seoSetting: null,
  }),
  actions: {
    async fetchInitial() {
      await Promise.all([
        this.loadCurrentSite(),
        // this.loadMenu(),
        // this.fetchUser()
      ]);
    },

    async fetchUser() {
      const { $cookies } = useNuxtApp();

      const token = $cookies.get("AuthTkn");

      if (token) {
        await useAuthStore.fetch();
      }
    },

    async loadCurrentSite() {
      const route = useRoute();

      if (!route.params.country) {
        return;
      }
      const nuxtApp = useNuxtApp();

      const [country, locale] = route.params.country.split("-");
      this.country = country;
      try {
        const [siteResponse, jsonResponse] = await Promise.all([
          useAPI(`api/sites/${country}`),
          useAPI(`api/country-wise-locale-content/${country}/${locale}`),
        ]);

        if (siteResponse.error && siteResponse.error.value) {

          const error = siteResponse.error.value
          this.country = null;
          if (import.meta.browser) {
            const cLocale = useCookie('CountryLocale', { path: '/', watch: 'shallow' })
            cLocale.value = ''
          }
          this.isLoading = false;

          // console.log(cLocale)
          nuxtApp.$cookies.remove("CountryLocale");
          if (error.response && (error.statusCode === 503 || error.statusCode === 500)) {
            this.maintenance = true;
          } else {
            console.error(`🐛🐛🐛 Error connecting '/api/sites/${country}'`);
          }
        } else {
        if (jsonResponse.data._rawValue) {
          const obj = {
            lng: locale,
            fallbackLng: "en",
            lazy: true,
            resources: jsonResponse.data._rawValue,
          };
          i18next.init(obj);
          this.translationData = obj;
        } else {
          this.translationData = null;
        }
        this.isLoading = false;

        this.currentSite = siteResponse.data._rawValue;
        this.pageLinks = (siteResponse.data._rawValue &&
          siteResponse.data._rawValue.pageLinks) ||
          {};
        this.seoSetting = (siteResponse.data._rawValue &&
          siteResponse.data._rawValue.seo) ||
          {};
        this.currentSiteSettings = siteResponse.data._rawValue && siteResponse.data._rawValue.settings || {};

        this.locale = locale;
        this.isRtl = ["he", "ar", "fa"].includes(locale);

          // nuxtApp.$cookies.set("CountryLocale", `${country}/${locale}`, {
          //   path: "/",
          // });

        let token = nuxtApp.$cookies.get("AuthTkn");

        if (token) {
          const expires_at = nuxtApp.$cookies.get("expires_at");

          nuxtApp.$cookies.set("AuthTkn", token, {
            expires: new Date(expires_at),
            secure: process.env.NODE_ENV === "production",
            path: `/${country}-${locale}`,
          });
        }
        }
      } catch (error) {

        this.country = null;
        if (import.meta.browser) {
          const cLocale = useCookie('CountryLocale', { path: '/', watch: 'shallow' })
          cLocale.value = ''
        }
        this.isLoading = false;

        // console.log(cLocale)
        nuxtApp.$cookies.remove("CountryLocale");
        if (error.response && (error.response.status === 503 || error.response.status === 500)) {
          this.maintenance = true;
        } else {
          console.error(`🐛🐛🐛 Error connecting '/api/sites/${country}'`);
        }
      }
    },

    async loadMenu() {
      try {
        const [
          productsResponse1,
          productsResponse2,
          categoriesResponse
        ] = await Promise.all([
          useAPI('/api/front/menu/products?type_id=1'),
          useAPI('/api/front/menu/products?type_id=2'),
          useAPI('/api/front/menu/categories/v2?type_id=3')
        ])
        if (productsResponse1.data._rawValue || productsResponse2.data._rawValue) {
          this.products = {
            type1: productsResponse1.data._rawValue ? productsResponse1.data._rawValue : [],
            type2: productsResponse2.data._rawValue ? productsResponse2.data._rawValue : [],
          }
        }
        this.categories = categoriesResponse.data._rawValue ? categoriesResponse.data._rawValue : []
      } catch (error) {
        console.error(error);
      }
    },
    async loadFeatures() {
      const nuxtApp = useNuxtApp();
      try {
        const { data } = await nuxtApp.$api("api/get-features")

        this.features = data;
      } catch (error) {
        console.error(error);
      }
    },
    async loadTranslationJsonData(route, url) {
      const [country, locale] = route.split("-");
      this.isLoading = true;
      try {
        const { data, error } = await useAPI(
          `api/country-wise-locale-content/${country}/${locale}`
        );
        if (data._rawValue) {
          const obj = {
            lng: locale,
            fallbackLng: "en",
            lazy: true,
            resources: data._rawValue,
          };
          i18next.init(obj);
          this.translationData = obj;
        } else {
          this.translationData = null;
        }
        this.defaultRoute = url
        this.isLoading = false;

      } catch (error) {
        this.defaultRoute = url
        // const cLocale = useCookie('CountryLocale')
        // cLocale.value = ''
        if (import.meta.browser) {
          const cLocale = useCookie('CountryLocale', { path: '/', watch: 'shallow' })
          cLocale.value = ''
        }
        this.isLoading = false;
        // nuxtApp.$cookies.remove("CountryLocale");
        if (error.response && (error.response.status === 503 || error.response.status === 500)) {
          this.maintenance = true;
        } else {
          console.error(`🐛🐛🐛 Error connecting '/api/sites/${country}'`);
        }
      }
    },

  },
});