<template>
  <NuxtLoadingIndicator color="#e2241b" class="fixed inset-x-0 top-0" />
  <div class="flex flex-col justify-between h-screen font-ff-condensed">
    <ClientOnly>
      <LazyOffline />
      <LazyNavigation v-if="!isMobile" />
      <LazyMobileNavigation v-else />
    </ClientOnly>

    <main
      id="app"
      class="relative w-full mx-auto mb-auto"
      style="max-width: 2560px"
    >
      <slot />
    </main>

    <LazyNewsletter v-if="footerEnable" />

    <ClientOnly>
      <LazyFooter v-if="footerEnable" />
      <LazyCookieBanner />
    </ClientOnly>

    <component :is="ModalsContainer" v-if="ModalsContainer" />
  </div>
</template>

<script setup>
import { useMainStore } from '@/store/index'
import { useLocaleStore } from '@/store/locales'
import { useAppStore } from '~/store/app'

// use methods
const route = useRoute()
const mainStore = useMainStore()
const localeStore = useLocaleStore()
const nuxtApp = useNuxtApp()

//data
const $i18nPath = nuxtApp.$i18nPath
const isMobile = ref(false)
const footerEnable = ref(false)
const windowWidth = ref(0)
const ModalsContainer = shallowRef(null)
const appStore = useAppStore()

onServerPrefetch(async () => {
  // ✅ this will work
  await mainStore.fetchInitial()
})

// computed
const isAboutUsPage = computed(() => {
  return route.path.startsWith($i18nPath(mainStore.pageLinks['about-us']))
})

const isHomePage = computed(() => {
  return route.name == 'country'
})

const isCuratorVisible = computed(() => {
  return isHomePage.value
})

mainStore.loadMenu()
localeStore.fetchLocales()

onBeforeMount(() => {
  if (import.meta.browser && nuxtApp.$pusher) {
    const channel = nuxtApp.$pusher.subscribe('deployments')
    channel.bind('new-deploy', (data) => appStore.setForceReload(true))
  }
})

onMounted(async () => {
  if (!import.meta.browser) return false
  window.onscroll = () => {
    if (document.documentElement.scrollTop > 10) {
      if (!footerEnable.value) {
        mainStore.loadFeatures()
        footerEnable.value = true
        window.onscroll = null
      }
    }
  }

  windowWidth.value = window.innerWidth
  if (windowWidth.value <= 1023) {
    isMobile.value = true
  } else {
    isMobile.value = false
  }

  await nextTick()
  window.addEventListener('resize', onResize)
  const initTime = setTimeout(async () => {
    const modalContainerComp = await import('vue-final-modal')
    ModalsContainer.value = modalContainerComp.ModalsContainer
    clearTimeout(initTime)
  }, 1300)
  const init2Time = setTimeout(() => {
    if (nuxtApp.$loadGTM) {
      nuxtApp.$loadGTM()
    }
    clearTimeout(init2Time)
    footerEnable.value = true
  }, 10000)
  const init3Time = setTimeout(() => {
    if (nuxtApp.$loadIntercom) {
      nuxtApp.$loadIntercom()
    }
    clearTimeout(init3Time)
  }, 6000)
  const countryLocale = useCookie('CountryLocale', {
    path: '/',
    watch: 'shallow',
  })
  countryLocale.value = `${mainStore.country}/${mainStore.locale}`
})

onBeforeUnmount(() => {
  window.onscroll = null
  window.removeEventListener('resize', onResize)
})

const onResize = () => {
  windowWidth.value = window.innerWidth
  if (windowWidth.value <= 1023) {
    isMobile.value = true
  } else {
    isMobile.value = false
  }
}
/***  head setup ***/
const baseAppUrl = nuxtApp.$config.public.APP_URL
const styles = []

// eslint-disable-next-line
let metaLinks = [
  { rel: 'alternate', href: `${baseAppUrl}`, hreflang: 'x-default' },
  {
    rel: 'canonical',
    href: `${baseAppUrl}${route.path.substring(1)}`,
  },
]
// Add alternate urls
mainStore.sites
  .filter((site) => site.slug === mainStore.country)
  .forEach((site) => {
    site.urls.forEach((item) => {
      metaLinks.push({
        rel: 'alternate',
        href: item.url,
        hreflang: item.locale_code,
      })
    })
  })
useHead({
  titleTemplate: '%s | Goccia Bike',
  link: metaLinks,
  style: styles,
  script: [
    {
      src: 'https://kit.fontawesome.com/91a0be131e.js',
      crossorigin: 'anonymous',
      defer: true,
    },
  ],
  htmlAttrs: {
    lang: mainStore.locale,
    dir: mainStore.isRtl ? 'rtl' : 'ltr',
  },
})
</script>
<style>
@import '@/assets/scss/global.scss';
@import 'vue-final-modal/style.css';
@import 'animate.css/animate.compat.css';
</style>
