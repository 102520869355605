import { default as _91page_93beDSl9Je7KMeta } from "/usr/src/app/pages/[country]/[page].vue?macro=true";
import { default as indexMo43axeGVFMeta } from "/usr/src/app/pages/[country]/checkout/[code]/easy-pay-result/index.vue?macro=true";
import { default as index8RBFfDxPwyMeta } from "/usr/src/app/pages/[country]/checkout/[code]/index.vue?macro=true";
import { default as _91status_93zQbzKVFdqCMeta } from "/usr/src/app/pages/[country]/checkout/[code]/payment-result/[status].vue?macro=true";
import { default as index8dcmj2pMNQMeta } from "/usr/src/app/pages/[country]/checkout/[code]/payubiz-result/index.vue?macro=true";
import { default as resultf6q8dHVSWIMeta } from "/usr/src/app/pages/[country]/checkout/[code]/result.vue?macro=true";
import { default as visa_45net_45resultq2zSq7mOCtMeta } from "/usr/src/app/pages/[country]/checkout/[code]/visa-net-result.vue?macro=true";
import { default as indexiC2XA9kvitMeta } from "/usr/src/app/pages/[country]/index.vue?macro=true";
import { default as _tokenS3PFiZdyZHMeta } from "/usr/src/app/pages/[country]/invitation/_token.vue?macro=true";
import { default as index1eQN3vFTuyMeta } from "/usr/src/app/pages/[country]/my-account/addresses/index.vue?macro=true";
import { default as indexjOLwuCpDGTMeta } from "/usr/src/app/pages/[country]/my-account/bikes/index.vue?macro=true";
import { default as recoverykwcrxYnRONMeta } from "/usr/src/app/pages/[country]/my-account/bikes/recovery.vue?macro=true";
import { default as returnR80s9rr9gVMeta } from "/usr/src/app/pages/[country]/my-account/bikes/return.vue?macro=true";
import { default as transfersjq3itXFt4Meta } from "/usr/src/app/pages/[country]/my-account/bikes/transfer.vue?macro=true";
import { default as warrantykr00Nt51hqMeta } from "/usr/src/app/pages/[country]/my-account/bikes/warranty.vue?macro=true";
import { default as indexC7On3iwKIYMeta } from "/usr/src/app/pages/[country]/my-account/index.vue?macro=true";
import { default as indexyod0bKbstNMeta } from "/usr/src/app/pages/[country]/my-account/orders/[code]/index.vue?macro=true";
import { default as indexovZMFqsrpwMeta } from "/usr/src/app/pages/[country]/my-account/orders/index.vue?macro=true";
import { default as _91slug_id_93rTeuqZVpP4Meta } from "/usr/src/app/pages/[country]/news/[slug_id].vue?macro=true";
import { default as _91slug_930u3Pwi1NwQMeta } from "/usr/src/app/pages/[country]/products/[slug].vue?macro=true";
import { default as _91type_93w52rTHu4oXMeta } from "/usr/src/app/pages/[country]/search/[type].vue?macro=true";
import { default as indexmySwA5trigMeta } from "/usr/src/app/pages/[country]/search/index.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
export default [
  {
    name: "country-page",
    path: "/:country()/:page()",
    component: () => import("/usr/src/app/pages/[country]/[page].vue")
  },
  {
    name: "country-checkout-code-easy-pay-result",
    path: "/:country()/checkout/:code()/easy-pay-result",
    meta: indexMo43axeGVFMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/easy-pay-result/index.vue")
  },
  {
    name: "country-checkout-code",
    path: "/:country()/checkout/:code()",
    meta: index8RBFfDxPwyMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/index.vue")
  },
  {
    name: "country-checkout-code-payment-result-status",
    path: "/:country()/checkout/:code()/payment-result/:status()",
    meta: _91status_93zQbzKVFdqCMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/payment-result/[status].vue")
  },
  {
    name: "country-checkout-code-payubiz-result",
    path: "/:country()/checkout/:code()/payubiz-result",
    meta: index8dcmj2pMNQMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/payubiz-result/index.vue")
  },
  {
    name: "country-checkout-code-result",
    path: "/:country()/checkout/:code()/result",
    meta: resultf6q8dHVSWIMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/result.vue")
  },
  {
    name: "country-checkout-code-visa-net-result",
    path: "/:country()/checkout/:code()/visa-net-result",
    meta: visa_45net_45resultq2zSq7mOCtMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/visa-net-result.vue")
  },
  {
    name: "country",
    path: "/:country()",
    component: () => import("/usr/src/app/pages/[country]/index.vue")
  },
  {
    name: "country-invitation-_token",
    path: "/:country()/invitation/_token",
    component: () => import("/usr/src/app/pages/[country]/invitation/_token.vue")
  },
  {
    name: "country-my-account-addresses",
    path: "/:country()/my-account/addresses",
    meta: index1eQN3vFTuyMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/addresses/index.vue")
  },
  {
    name: "country-my-account-bikes",
    path: "/:country()/my-account/bikes",
    meta: indexjOLwuCpDGTMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/index.vue")
  },
  {
    name: "country-my-account-bikes-recovery",
    path: "/:country()/my-account/bikes/recovery",
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/recovery.vue")
  },
  {
    name: "country-my-account-bikes-return",
    path: "/:country()/my-account/bikes/return",
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/return.vue")
  },
  {
    name: "country-my-account-bikes-transfer",
    path: "/:country()/my-account/bikes/transfer",
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/transfer.vue")
  },
  {
    name: "country-my-account-bikes-warranty",
    path: "/:country()/my-account/bikes/warranty",
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/warranty.vue")
  },
  {
    name: "country-my-account",
    path: "/:country()/my-account",
    meta: indexC7On3iwKIYMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/index.vue")
  },
  {
    name: "country-my-account-orders-code",
    path: "/:country()/my-account/orders/:code()",
    meta: indexyod0bKbstNMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/orders/[code]/index.vue")
  },
  {
    name: "country-my-account-orders",
    path: "/:country()/my-account/orders",
    meta: indexovZMFqsrpwMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/orders/index.vue")
  },
  {
    name: "country-news-slug_id",
    path: "/:country()/news/:slug_id()",
    component: () => import("/usr/src/app/pages/[country]/news/[slug_id].vue")
  },
  {
    name: "country-products-slug",
    path: "/:country()/products/:slug()",
    component: () => import("/usr/src/app/pages/[country]/products/[slug].vue")
  },
  {
    name: "country-search-type",
    path: "/:country()/search/:type()",
    component: () => import("/usr/src/app/pages/[country]/search/[type].vue")
  },
  {
    name: "country-search",
    path: "/:country()/search",
    component: () => import("/usr/src/app/pages/[country]/search/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  }
]